import React from "react";
import { styled } from "styled-components";
import axios from "axios";

import logo from "../../assets/images/logo/classic-logo.svg";
import homelogo from "../../assets/images/logo/classic-home-logo.png";
import fbIcon from "../../assets/images/icons/fb-icon.svg";
import insta from "../../assets/images/icons/insta-icon.svg";
import yt from "../../assets/images/icons/yt-icon.svg";
import certificate from "../../assets/images/other/Certificate.png";
import { useState } from "react";
import CertificateModal from "../modal/CertificateModal";
import { useNavigate } from "react-router-dom";
import AccoladesLogo from "../../assets/images/logo/accolades_logo.svg";
import { useEffect } from "react";
import { ListServices } from "../../axios/api";

function Footer() {
  const [isModal, setisModal] = useState(false);
  const [services, setservices] = useState([]);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    axios.get(ListServices).then((response) => {
      const { data, StatusCode } = response.data.app_data;

      if (StatusCode === 6000) {
        setservices(data);
      } else {
        setservices([]);
      }
    });
  }, []);

  const navigate = useNavigate();
  return (
    <Section>
      <Container>
        <TopArea>
          <LogoDiv onClick={() => navigate("/")}>
            <img src={logo} alt="" />
          </LogoDiv>

          <AddressInfo>
            <BreadCrumb>Address /</BreadCrumb>
            <Item>
              <Label className="lap">Office ::</Label>
              <Label className="mob">:: Office ::</Label>
              <Value>
                4th Floor, Classic Capitol, MRA-83, Mavelipuram, Kakkanad,
                Cochin
              </Value>
            </Item>
            <Item>
              <Label className="lap">Factory ::</Label>
              <Label className="mob">:: Factory ::</Label>
              <Value>Kinfra Industrial Park, Infopark Campus, Kakkanad</Value>
            </Item>
          </AddressInfo>

          <ContactInfo>
            <BreadCrumb>Contact /</BreadCrumb>

            <Item class="item">
              <Label className="contactLabel lap">E ::</Label>
              <Label className="contactLabel mob">:: Email ::</Label>
              <Value ><a href="mailto:mail@classicinsides.com">mail@classicinsides.com</a></Value>
            </Item>

            <Item class="item">
              <Label className="contactLabel lap">M ::</Label>
              <Label className="contactLabel mob">:: Mobile ::</Label>
              <Value>
                <div>
                <a href="tel:+919072341177">+91 907 23 41177</a> <br />
                <a href="tel:+919072888511">+91 9072 88 8511</a>
                </div>
              </Value>
            </Item>
          </ContactInfo>

          <CertificateInfo>
            <BreadCrumb className="certificate-title">
              ISO 9001:2015 CERTIFIED
            </BreadCrumb>
            <ImgContainer onClick={() => setisModal(true)} target="_blank">
              <img src={certificate} alt="" />
            </ImgContainer>
          </CertificateInfo>

          <SocialmediaInfoMob>
            <Icon
              href="https://www.facebook.com/ClassicInsides/"
              target="_blank"
            >
              <img src={fbIcon} alt="" />
            </Icon>
            <Icon
              href="https://www.instagram.com/paul_classicinsides/"
              target="_blank"
            >
              <img src={insta} alt="" />
            </Icon>
            <Icon
              href="https://www.youtube.com/@ClassicInsides"
              target="_blank"
            >
              <img src={yt} alt="" />
            </Icon>
          </SocialmediaInfoMob>

          <HomesLogoMob>
            <img src={homelogo} alt="" />
          </HomesLogoMob>
        </TopArea>

        <Links>
          {services.map((item) => (
            <LinkInfo href={`/service/${item.slug}`}>{item.name}</LinkInfo>
          ))}
        </Links>

        <Separator></Separator>
        <BottomArea>
          <LeftSection>
          <CopyRightInfo>
          <LinkInfo href='/terms-and-conditions'>Terms And Conditions</LinkInfo>
          <LinkInfo href='/privacy-policy'>Privacy Policy</LinkInfo>
            </CopyRightInfo>
            <div className="copy-right">
            <CopyRightInfo>
              Copyright©{currentYear} Classic Insides All Rights Reserved.
            </CopyRightInfo>
            <LogoLink target="_blank" href="https://accoladesintegrated.com/">
              Designed and Developed By
              <CompanyLogo src={AccoladesLogo} />
            </LogoLink>
            </div>
          </LeftSection>

          <RightSection>
            <HomesLogo href="https://www.classichomes.in/" target="_blank">
              <img src={homelogo} alt="" />
            </HomesLogo>
            <SocialmediaInfo>
              <Icon
                href="https://www.facebook.com/ClassicInsides/"
                target="_blank"
              >
                <img src={fbIcon} alt="" />
              </Icon>
              <Icon
                href="https://www.instagram.com/paul_classicinsides/"
                target="_blank"
              >
                <img src={insta} alt="" />
              </Icon>
              <Icon
                href="https://www.youtube.com/@ClassicInsides"
                target="_blank"
              >
                <img src={yt} alt="" />
              </Icon>
            </SocialmediaInfo>
          </RightSection>
        </BottomArea>
      </Container>

      <CertificateModal isModal={isModal} setModal={setisModal} />
    </Section>
  );
}

export default Footer;

const Section = styled.div`
  background-color: black;
  padding: 25px;
  @media (max-width: 1024px) {
    padding: 8px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: 0px auto;
  padding: 40px 0px 0px 0px;
  @media (max-width: 1325px) {
    width: 88%;
  }
  @media (max-width: 1024px) {
    width: 95%;
  }
`;

const LogoDiv = styled.div`
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 40px;
  position: relative;
  @media (min-width: 1875px) {
    width: 20%;
  }
  @media (min-width: 768px) {
    justify-content: start;
  }
  @media (max-width: 580px) {
    width: 100%;
    order: 4;
  }

  &::after {
    content: "";
    position: absolute;
    top: 30px;
    width: 1px;
    height: 60%;
    right: 0;
    background-color: #ffffff;
    @media (min-width: 1875px) {
      height: 80%;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }

  img {
    width: 90%;
    display: block;
    @media (max-width: 768px) {
      width: 50%;
    }
  }
`;

const AddressInfo = styled.div`
  display: flex;
  width: 25%;
  padding: 10px;
  flex-direction: column;
  padding: 0px 40px;
  position: relative;
  @media (min-width: 1875px) {
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 1024px) {
    padding: 0px 25px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    div {
      width: 100% !important;
    }
  }

  @media (max-width: 580px) {
    order: 1;
  }
  &::after {
    content: "";
    position: absolute;
    top: 30px;
    width: 1px;
    height: 60%;
    right: 0;
    background-color: #ffffff;
    @media (min-width: 1875px) {
      height: 80%;
    }
    @media (max-width: 768px) {
      left: 0;
      height: 90%;
    }
    @media (max-width: 580px) {
      transform: rotate(90deg);
      bottom: -85px;
      left: auto;
      top: auto;
      right: auto;
      height: 60%;
    }
  }
`;

const ContactInfo = styled.div`
  display: flex;
  width: 25%;
  padding: 10px;
  flex-direction: column;
  padding: 0px 35px;
  padding: 0px 40px;
  position: relative;
  @media (min-width: 1875px) {
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 1024px) {
    padding: 0px 25px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    div {
      width: 100% !important;
    }
  }
  @media (max-width: 580px) {
    order: 2;
    margin-top: 15px !important;
  }
  &::after {
    content: "";
    position: absolute;
    top: 30px;
    width: 1px;
    height: 60%;
    right: 0;
    background-color: #ffffff;
    @media (min-width: 1875px) {
      height: 80%;
    }
    @media (max-width: 768px) {
      height: 90%;
    }
    @media (max-width: 580px) {
      transform: rotate(90deg);
      bottom: -85px;
      left: auto;
      top: auto;
      right: auto;
      height: 60%;
    }
  }
`;

const CertificateInfo = styled.div`
  display: flex;
  width: 25%;
  padding: 10px;
  flex-direction: column;
  @media (min-width: 1875px) {
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 1024px) {
    padding: 0px 25px;
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 580px) {
    order: 3;
  }
  .certificate-title {
    text-align: center;
    @media (min-width: 1024px) {
      font-size: 11px;
    }
    @media (max-width: 768px) {
      text-align: start;
      padding: 0px 20px;
    }
    @media (max-width: 580px) {
      width: 100%;
      text-align: center;
      padding: 10px 20px;
      &::before {
        display: none !important;
      }
    }
    @media (max-width: 380px) {
      padding: 10px 10px;
    }
  }
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin: 20px 0px 0px 0px;
`;

const BottomArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width:580px) {
    justify-content: center;
  }
`;

const TopArea = styled.div`
  display: flex;
  color: #fff;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    div {
      width: 40%;
      padding: 10px;
    }
  }
  @media (max-width: 580px) {
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    div {
      width: 88%;
      padding: 10px;
    }
  }
`;

const SocialmediaInfo = styled.div`
  display: none;
  width: 30%;
  padding: 20px 0px;
  align-items: center;
  justify-content: end;
  @media (min-width: 580px) {
    display: flex;
  }
`;

const SocialmediaInfoMob = styled.div`
  display: flex;
  width: 30%;
  padding: 20px 0px;
  align-items: center;
  justify-content: center;
  order: 5;
  @media (min-width: 580px) {
    display: none;
  }
`;

const CopyRightInfo = styled.p`
  font-family: "raleway_regular";
  font-size: 14px;
  color: #fff;
  @media (max-width: 580px) {
    width: 100%;
    text-align: center;
    font-size: 13px;
  }
`;

const BreadCrumb = styled.p`
  font-size: 13px;
  font-family: "raleway_medium";
  color: var(--tertiary-cl);
  @media (min-width: 1875px) {
    font-size: 16px;
  }
  @media (max-width: 768px) {
    width: 100%;
    p {
      text-align: center;
    }
  }
  @media (max-width: 580px) {
    text-align: center;
    position: relative;
    margin-bottom: 0px;
    &::before {
      content: "/";
      position: absolute;
      top: 0;
      left: 38%;
    }
  }
  @media (max-width: 380px) {
    &::before {
      left: 36%;
    }
  }
`;

const Item = styled.div`
  display: flex;
  font-size: 14px;
  font-family: "raleway_medium";
  align-items: center;
  justify-content: flex-start;
  .mob {
    display: none;
  }
  .lap {
    display: flex;
  }
  @media (min-width: 1875px) {
    font-size: 18px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 580px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .mob {
      display: flex;
    }
    .lap {
      display: none;
    }
  }
  .contactLabel {
    width: 3ch;
    @media (max-width: 580px) {
      width: 100%;
    }
  }
`;

const Label = styled.p`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 8ch;
  position: relative;
  @media (max-width: 968px) {
    width: 9ch;
  }
  @media (max-width: 580px) {
    width: 100%;
    justify-content: center;
  }
`;

const Value = styled.p`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  font-family: "raleway_light";
  width: 30ch;
  a{
    color: #fff;
  text-decoration: none;
  cursor: pointer;
  }
  @media (min-width: 1875px) {
    font-size: 18px;
  }
  @media (max-width: 1024px) {
    font-size: 11px;
  }
  @media (max-width: 580px) {
    font-size: 15px;
    text-align: center;
    margin-top: 0px;
    width: 100%;
    justify-content: center;
    line-height: 20px;
    margin-bottom: 0px;
  }
`;

const Icon = styled.a`
  text-decoration: none;
  width: 20%;
  margin: 10px 5px;
  transition: all 0.4s ease-in-out;
  @media (max-width: 968px) {
    width: 11%;
  }
  @media (max-width: 768px) {
    width: 14%;
  }
  @media (max-width: 580px) {
    width: 14% !important;
  }
  &:hover {
    transform: scale(1.2);
  }
  img {
    display: block;
    width: 100%;
  }
`;

const ImgContainer = styled.a`
  width: 100%;
  margin: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 75% !important;
  }
  @media (max-width: 580px) {
    width: 100% !important;
    img {
      width: 30% !important;
    }
  }
  &::after {
    content: "";
    top: -12px;
    height: 85%;
    width: 60%;
    border: 1px solid #fff;
    position: absolute;
    @media (max-width: 1024px) {
      height: 125%;
    }
    @media (max-width: 1024px) {
      height: 110%;
    }
    @media (max-width: 580px) {
      height: 85%;
      top: -3px;
      width: 37%;
    }
  }
  img {
    display: block;
    width: 43%;
    z-index: 100;
    @media (max-width: 1024px) {
      width: 50%;
    }
  }
`;

const RightSection = styled.div`
  display: flex;
  justify-content: end;
  flex: 1;

  @media (max-width: 580px) {
    display: none;
  }
`;

const HomesLogo = styled.a`
  width: 190px;
  display: flex;
  text-decoration: none;
  cursor: pointer;

  @media (max-width: 968px) {
    width: 20%;
  }

  img {
    width: 100%;
    display: block;
  }
`;

const HomesLogoMob = styled.div`
  width: 185px !important;
  display: flex;
  order: 6;
  @media (min-width: 580px) {
    display: none;
  }
  img {
    width: 100%;
    display: block;
  }
`;

const CompanyLogo = styled.img`
  width: 135px;
  height: auto;
`;

const LogoLink = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap:7px;
  color:#ffff;
  font-size: 12px;

  @media (max-width: 580px) {
    width: 100%;
  }
`;

const LeftSection = styled.div`
 
  @media (max-width: 1024px) {
    .copy-right{
      flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    gap: 3px;
    }
    
  }

  @media screen and (max-width:580px) {
    .copy-right{
      justify-content: center;
    }
  }
  .copy-right{
    display: flex;
  align-items: center;
  gap: 6px;
  }
`;

const Links = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  margin: 25px 0;

  @media (max-width: 1250px) {
    row-gap: 25px;
  }

  @media (max-width: 475px) {
    gap: 0px;
    justify-content: flex-start;
  }
`;

const LinkInfo = styled.a`
  /* width:20%; */
  color: #fff;
  font-family: "raleway_regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  cursor: pointer;
  border-right: 1px solid #fff;
  padding: 2px 20px;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    color: #eb6502;
  }

  &:last-child {
    border: none;
  }

  @media (max-width: 475px) {
    margin: 0 auto;
    width: 30%;
    padding: 5px 20px;

    &:last-child {
      border: none;
      margin: 0 13px;
    }

    &:nth-child(even) {
      border: none;
      margin: 0 auto;
    }
  }

  @media (max-width: 375px) {
    font-size: 13px;
  }
`;
