import React from 'react';
import styled from 'styled-components';
import first from './../../../assets/images/projectPage/1.svg';
import second from './../../../assets/images/projectPage/2.svg';
import third from './../../../assets/images/projectPage/3.svg';
import fourth from './../../../assets/images/projectPage/4.svg';

function NewArrivals() {
  return (
    <Section>
      <BoxContainer>
        <Box>
          <img src={first} alt="First" />
          <h1>Free Shipping</h1>
          <p>Order above $200</p>
        </Box>
        <Box>
          <img src={second} alt="Second" />
          <h1>Money-back</h1>
          <p>30 days guarantee</p>
        </Box>
        <Box>
          <img src={third} alt="Third" />
          <h1>Secure Payments</h1>
          <p>Secured by Stripe</p>
        </Box>
        <Box>
          <img src={fourth} alt="Fourth" />
          <h1>24/7 Support</h1>
          <p>Phone and Email support</p>
        </Box>
      </BoxContainer>
    </Section>
  );
}

export default NewArrivals;

const Section = styled.div`
  width: 80%;
  margin: 30px auto;
`;

const BoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  @media (min-width: 1024px) {
    /* Large screens: 4 boxes per row */
    flex-direction: row;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    /* Medium screens: 2 boxes per row */
    flex-direction: row;
  }

  @media (max-width: 767px) {
    /* Small screens: 1 box per row */
    flex-direction: column;
  }
`;

const Box = styled.div`
  flex: 1 1 calc(25% - 16px); /* Default: 4 boxes per row for large screens */
  padding: 48px 32px;
  box-sizing: border-box;
  background-color: #f5f5f5;
  text-align: justify;

  @media (min-width: 768px) and (max-width: 1023px) {
    /* Medium screens: 2 boxes per row */
    flex: 1 1 calc(50% - 16px);
  }

  @media (max-width: 767px) {
    /* Small screens: 1 box per row */
    flex: 1 1 100%;
    margin-bottom: 16px; /* Add margin-bottom for spacing between rows on small screens */
  }
`;
