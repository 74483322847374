import React, { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Container, Typography, Box } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {useNavigate } from 'react-router-dom';


// Define custom breakpoints
const customTheme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 768,
      desktop: 1024,
    },
  },
});

const faqData = [
  { id: 1, question: "Can I reschedule if I miss a session?", answer: "Yes, you can reschedule for another available Saturday session with prior notice." },
  { id: 2, question: "Do you provide certificates?", answer: "No, this is a hands-on workshop focused on practical skills rather than certification." },
  { id: 3, question: "Is there a discount for large groups?", answer: "Yes, we offer special pricing for groups of five or more. Contact us for details." },
];

const WorkshopFaq = ({faqs}) => {
  const [openFaqs, setOpenFaqs] = useState({});
  const navigate = useNavigate();
  const handleClick = (path)=>{
    navigate(path);
}
  const handleToggle = (id) => {
    setOpenFaqs((prev) => ({ ...prev, [id]: !prev[id] }));
  };
  // if (!Array.isArray(faqs) || faqs.length === 0) {
  //   return null;
  // }
  return (
    <ThemeProvider theme={customTheme}>
      <Container sx={{ py: 2, px: { mobile: 2, tablet: 2, desktop: 6 } }}>
        <Typography
          variant="h4"
          sx={{ mb: 4, fontWeight: 700, fontSize: { mobile: "1.8rem", desktop: "2.2rem" } }}
        >
          FAQ
        </Typography>

        {faqData.map((faq) => (
          <Box
            key={faq.id}
            sx={{
              mb: 3,
              borderRadius: '12px',
              border: '2px solid #ccc',
              overflow: 'hidden',
              p: 1,
            }}
          >
            <ListItemButton onClick={() => handleToggle(faq.id)}>
              <ListItemText
                primary={
                  <Typography variant="h6" sx={{ flex: 1, fontSize: { mobile: "14px", tablet: "16px", desktop: "16px" } }}>
                    {faq.question}
                  </Typography>
                }
              />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {openFaqs[faq.id] ? <ExpandLess fontSize="large" /> : <ExpandMore fontSize="large" />}
              </Box>
            </ListItemButton>

            <Collapse in={openFaqs[faq.id]} timeout="auto" unmountOnExit>
              <Box sx={{ p: 3, pt: 0 }}>

                <Typography variant="body1" sx={{ fontSize: { mobile: "12px", tablet: "14px", desktop: "14px" } }}>
                  {faq.answer}
                </Typography>
              </Box>
            </Collapse>
          </Box>
        ))}
        <Typography variant="body1" sx={{ fontFamily: "'Quicksand', 'Urbanist', sans-serif", fontSize: { mobile: "12px", tablet: "16px", desktop: "16px" }, color: "#333" }}>
          Didn't find the answer you were looking for?{" "}
          <Typography
            component="a"
            onClick={()=>handleClick('/contact')}
            sx={{
              color: "orange",
              fontWeight: "bold",
              cursor: "pointer",
              "&:hover": { color: "darkorange" },
            }}
          >
            Contact Us
          </Typography>
        </Typography>

      </Container>
    </ThemeProvider>
  );
};

export default WorkshopFaq;
