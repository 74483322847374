import React from 'react'
import Header from '../components/includes/Header'
import Helmet from '../components/utils/Helmet'
import ProdcutLanding from '../components/sliders/ProductLandingSlider'
import ProductShow from '../components/pages/productPages/ProductShow'
import NewArrivalsSlider from '../components/sliders/NewArrivalSlider'
import NewArrivals from '../components/pages/productPages/NewArrivals'
import LastSection from '../components/pages/productPages/LastSection'
import Footer from '../components/includes/Footer'


function ProductPage() {
  return (
    <>
        <Helmet
        titleData="Product-Page"
        descriptionData="Product-Page"
      />
      <Header />
      <ProdcutLanding/>
      <ProductShow/>
     <NewArrivalsSlider/>
    <NewArrivals/> 
     <LastSection/>
      <Footer />
    
    </>
  )
}

export default ProductPage