import React, { useEffect, useRef, useState } from 'react'
import Header from '../components/includes/Header'
import Footer from '../components/includes/Footer'
import WorkshopBanner from '../components/pages/workshop/WorkshopBanner'
import WorkshopDetails from '../components/pages/workshop/WorkshopDetails'
import WorkshopAwaits from '../components/pages/workshop/WorkshopAwaits'
import WorkshopForm from '../components/pages/workshop/WorkshopForm'
import WorkshopSlider from '../components/pages/workshop/WorkshopSlider'
import WorkshopFaq from '../components/pages/workshop/WorkshopFaq'
import axios from 'axios';
import { WorkshopTestimonials, WorkshopFaqs } from '../../src/axios/api'

const Workshop = () => {
  const formRef = useRef(null);

  // State for fetched data
  const [testimonials, setTestimonials] = useState([]);
  const [faqs, setFaqs] = useState([]);


  const scrollToForm = () => {
    if (formRef.current) {
      const formTop = formRef.current.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: formTop - 100, behavior: "smooth" }); // Moves directly with offset
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [testimonialResponse, faqResponse] = await Promise.all([
          axios.get(WorkshopTestimonials),
          axios.get(WorkshopFaqs),
        ]);

        // Extract actual data from response and filter out failed responses
        const testimonialData =
          testimonialResponse.data?.app_data?.data?.title === 'Failed'
            ? []
            : testimonialResponse.data?.app_data?.data || [];

        const faqData =
          faqResponse.data?.app_data?.data?.title === 'Failed'
            ? []
            : faqResponse.data?.app_data?.data || [];

        setTestimonials(testimonialData);
        setFaqs(faqData);
        console.log(testimonialData, faqData);
        
      } catch (error) {
        console.error('Error fetching workshop data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Header />
      <WorkshopBanner scrollToForm={scrollToForm} />
      <WorkshopDetails scrollToForm={scrollToForm} />
      <WorkshopAwaits />
      <WorkshopForm ref={formRef} />
      <WorkshopSlider testimonials={testimonials} />
      <WorkshopFaq faqs={faqs} />
      <Footer />
    </>
  )
}

export default Workshop