import { Box, Button, Typography, ThemeProvider, createTheme, useMediaQuery } from "@mui/material";
import React from "react";
import bg from "../../../assets/images/workshop/wrk_banner.jpg";

const WorkshopBanner = ({ scrollToForm }) => {
  const customTheme = createTheme({
    breakpoints: {
      values: {
        mobile: 0,
        tablet: 768,
        desktop: 1024,
        large: 1440,
      },
    },
  });

  const isMobile = useMediaQuery(customTheme.breakpoints.down("tablet"));
  const isTablet = useMediaQuery(customTheme.breakpoints.between("tablet", "desktop"));
  const isLargeScreen = useMediaQuery(customTheme.breakpoints.between("desktop", "large"));
  const isLargest = useMediaQuery(customTheme.breakpoints.up("large"));


  return (
    <ThemeProvider theme={customTheme}>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: isMobile ? "450px" : isTablet ? "500px" : isLargeScreen ? "50vh" : "80vh",
          backgroundImage: `url(${bg})`,
          backgroundSize: isMobile ? "contain" : "cover",
          backgroundPosition: "top center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          alignItems: isMobile ? "flex-end" : "center",
          justifyContent: "flex-start",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background:
              "linear-gradient(90deg, rgba(0, 0, 0, 0.8) 30%, rgba(0, 0, 0, 0.6) 40%, rgba(0, 0, 0, 0.2) 80%, transparent)",
          }}
        />
        <Box
          sx={{
            position: "relative",
            zIndex: 2,
            padding: isMobile ? "20px" : "50px",
            maxWidth: "650px",
            color: "white",
            marginLeft: isLargeScreen ? "40px" : isLargest ? "130px" : 0
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontFamily: "'Quicksand Variable', sans-serif",
              fontWeight: 700,
              fontSize: isMobile ? "26px" : isTablet ? "36px" : "48px",
              marginBottom: "10px",
            }}
          >
            Join the Classic Insides Woodworking Workshop
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "'Urbanist', sans-serif",
              fontWeight: 700,
              fontSize: isMobile ? "12px" : isTablet ? "14px" : "16px",
              marginBottom: "20px",
            }}
          >
            Unleash your creativity and learn the art of woodworking, perfect for all ages and skill levels.
          </Typography>
          <Button onClick={scrollToForm} variant="contained" color="warning" size={isMobile ? "small" : "medium"}>
            Reserve Your Spot Now
          </Button>
          <Typography variant="body1" sx={{ fontSize: "10px", marginBottom: "20px", marginTop: "10px" }}>
            Spots are limited! Over 100 attendees have loved this experience.
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default WorkshopBanner;
