import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Box, Typography, Avatar, Rating, createTheme, ThemeProvider, useMediaQuery } from "@mui/material";
import prof from "../../../assets/images/workshop/sliderprof.png";

// Define custom breakpoints
const customTheme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 768,
      desktop: 1024,
    },
  },
});

const reviews = [
  { id: 1, rating: 4.5, description: "The woodworking workshop was an amazing experience! The hands-on training, expert guidance, and friendly atmosphere made learning so much fun. I left with a handcrafted piece and a newfound passion for woodworking!", name: "John Doe", role: "Software Engineer", profilePic: prof },
  { id: 2, rating: 4.5, description: "The woodworking workshop was an amazing experience! The hands-on training, expert guidance, and friendly atmosphere made learning so much fun. I left with a handcrafted piece and a newfound passion for woodworking!", name: "Jane Smith", role: "Product Designer", profilePic: prof },
  { id: 3, rating: 4.5, description: "The woodworking workshop was an amazing experience! The hands-on training, expert guidance, and friendly atmosphere made learning so much fun. I left with a handcrafted piece and a newfound passion for woodworking!", name: "Alice Brown", role: "UI/UX Designer", profilePic: prof },
  { id: 4, rating: 4.5, description: "The woodworking workshop was an amazing experience! The hands-on training, expert guidance, and friendly atmosphere made learning so much fun. I left with a handcrafted piece and a newfound passion for woodworking!", name: "Mike Johnson", role: "Project Manager", profilePic: prof },
  { id: 5, rating: 4.5, description: "The woodworking workshop was an amazing experience! The hands-on training, expert guidance, and friendly atmosphere made learning so much fun. I left with a handcrafted piece and a newfound passion for woodworking!", name: "Sarah Lee", role: "Software Engineer", profilePic: prof },
];

const ReviewCard = ({ review }) => (
  <Box
    sx={{
      backgroundColor: "#EB6502",
      borderRadius: 2,
      padding: 3,
      textAlign: "left",
      color: "white",
      maxWidth: 280,
      mx: "auto",
      minHeight: "320px",
      display: "flex",
      flexDirection: "column",
    }}
  >
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Rating value={review.rating} precision={0.5} readOnly sx={{ color: "white", fontSize: "0.9rem" }} />
        <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "0.8rem" }}>({review.rating} Rating)</Typography>
      </Box>
      <Typography variant="body1" sx={{ mt: 4, fontSize: "16px" }}>{review.description}</Typography>
    </Box>

    <Box sx={{ flexGrow: 1 }} />

    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
      <Avatar src={review.profilePic} sx={{ width: 48, height: 48 }} />
      <Box sx={{ ml: 2 }}>
        <Typography variant="h6" sx={{ fontSize: "12.1px" }}>{review.name}</Typography>
        <Typography variant="body2" sx={{ fontSize: "9px" }}>{review.role}</Typography>
      </Box>
    </Box>
  </Box>
);

const WorkshopSlider = ({ testimonials }) => {

  // UseMediaQuery to control Swiper slides per view
  const isMobile = useMediaQuery(customTheme.breakpoints.down("tablet")); // < 768px
  const isTablet = useMediaQuery(customTheme.breakpoints.between("tablet", "desktop")); // 768px - 1439px
  const isDesktop = useMediaQuery(customTheme.breakpoints.up("desktop")); // ≥ 1440px
  // if (!Array.isArray(testimonials) || testimonials.length === 0) {
  //   return null;
  // }
  return (
    <ThemeProvider theme={customTheme}>
      <Box sx={{ padding: { mobile: 2, tablet: 3, desktop: 4 }, margin: { mobile: 2, tablet: 3 } }}>
        <Typography variant="h4" sx={{ textAlign: "left", marginBottom: "32px", fontWeight: 700 }}>
          Testimonials
        </Typography>

        <Swiper
          spaceBetween={20}
          slidesPerView={isMobile ? 1 : isTablet ? 2.5 : 4} // Responsive slides per view
          grabCursor={true}
        >
          {reviews.map((review) => (
            <SwiperSlide key={review.id}>
              <ReviewCard review={review} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </ThemeProvider>
  );
};

export default WorkshopSlider;
