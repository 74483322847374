import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import MainImage1 from '../../../../src/assets/images/projectPage/projuctView.svg';
import MainImage2 from '../../../../src/assets/images/projectPage/sub image.jpeg';
import MainImage3 from '../../../../src/assets/images/projectPage/01.jpeg';
import MainImage4 from '../../../../src/assets/images/projectPage/02.jpeg';
import MainImage5 from '../../../../src/assets/images/projectPage/03.jpeg';
import leftArrow from '../../../../src/assets/images/projectPage/leftArrow.svg';
import rightSideArrow from '../../../../src/assets/images/projectPage/rightSideArrow.svg';


const products = 
[
  {
    id: 1,
    name: 'Meryl Lounge Chair',
    description: 'The gently curved lines accentuated by sewn details are kind to your body and pleasant to look at. Also, there’s a tilt and height-adjusting mechanism that’s built to outlast years of ups and downs.',
    price: 'Rs 8,888',
    images: [MainImage1,MainImage2,MainImage3,MainImage4,MainImage5],
  },
  {
    id: 2,
    name: 'Caden Dining Set',
    description: 'A stylish dining set with a modern design, perfect for family gatherings and dinner parties. The set includes a durable table and comfortable chairs, crafted to last for years.',
    price: 'Rs 24,999',
    images: [MainImage2],
  },
  {
    id: 3,
    name: 'Avery Recliner Sofa',
    description: 'Experience the ultimate relaxation with Avery Recliner Sofa. Its plush cushioning and ergonomic design provide superior comfort, making it the perfect spot to unwind after a long day.',
    price: 'Rs 12,499',
    images: [MainImage3],
  },
  {
    id: 4,
    name: 'Kendall Coffee Table',
    description: 'A sleek and elegant coffee table that adds a touch of sophistication to any living room. Its minimalist design and sturdy construction make it a must-have piece of furniture.',
    price: 'Rs 7,499',
    images: [MainImage4],
  },
  {
    id: 5,
    name: 'Harper Bed Frame',
    description: 'Upgrade your bedroom with the Harper Bed Frame,featuring a modern design with clean lines and a sturdy build. It’s the perfect foundation for a good night’s sleep.',
    price: 'Rs 18,999',
    images: [MainImage5],
  },
];


function ProductShow() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentImage, setCurrentImage] = useState(products[0].images[0]);

  const currentProduct = products[currentIndex];

  useEffect(() => {
    setCurrentImage(currentProduct.images[0]);
  }, [currentIndex, currentProduct.images]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % products.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + products.length) % products.length);
  };


 
  return (
    <Section>

      <HeaderWrapper>
        <HeaderText>{`${currentIndex + 1}/ ${products.length}`}</HeaderText>
        <ArrowWrapper>
          <Arrow src={leftArrow} alt="Left Arrow" onClick={handlePrevious} />
          <Arrow src={rightSideArrow} alt="Right Arrow" onClick={handleNext} />
        </ArrowWrapper>
      </HeaderWrapper>

      <GridWrapper>
        <LeftContent>
          <ProductTitle>{currentProduct.name}</ProductTitle>
          <ProductDescription>
            {currentProduct.description}
          </ProductDescription>
          <ProductPrice>{currentProduct.price}</ProductPrice>
          <StyledButton>Buy Now</StyledButton>
          <ShippingInfo>Free 3-5 day shipping  •  Tool-free assembly  •  30-day trial</ShippingInfo>
        </LeftContent>
        <RightContent>
          <ImageWrapper>
            <MainProductImage src={currentImage} alt="Main product" />
            <ThumbnailWrapper>
              {currentProduct.images.map((image, index) => (
                <Thumbnail
                  key={index}
                  src={image}
                  alt={`Thumbnail ${index + 1}`}
                  isActive={image === currentImage}
                  onClick={() => setCurrentImage(image)}
                />
              ))}
            </ThumbnailWrapper>
          </ImageWrapper>
        </RightContent>
      </GridWrapper>
    </Section>
  );
}

export default ProductShow;

const Section = styled.div`
  width: 80%;
  margin: 50px auto 0;
  @media (max-width: 1200px) {
    margin: 70px auto;
  }
  @media (max-width: 1024px) {
    width: 90%;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 20px;
`;

const HeaderText = styled.h1`
  margin: 0;
`;

const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

const Arrow = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.2);
  }
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ProductTitle = styled.h2`
  margin: 0 0 10px;
  font-size: 44px;
`;

const ProductDescription = styled.p`
  margin: 0 0 10px;
  font-size: 16px;
  width: 43%;
`;

const ProductPrice = styled.p`
  margin: 0 0 20px;
  font-size: 24px;
`;

const StyledButton = styled.button`
  padding: 18px 40px;
  background-color: rgba(58, 163, 159, 1);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-self: flex-start;

  &:hover {
    background-color: rgba(45, 131, 128, 1);
  }
`;

const ShippingInfo = styled.p`
  margin: 20px 0 0;
  font-size: 16px;
`;

const RightContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MainProductImage = styled.img`
  width: 400px;
  height: 400px;
  object-fit: cover;
  margin-bottom: 10px;
`;

const ThumbnailWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

const Thumbnail = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  box-sizing: border-box;
  border: 2px solid transparent;
  
  &:hover {
    border-color: rgba(58, 163, 159, 1);
  }

  ${({ isActive }) => isActive && `
    border-color: rgba(58, 163, 159, 1);
  `}
`;
