import { forwardRef, useEffect, useState } from "react";
import { Box, Button, Checkbox, createTheme, ThemeProvider, Typography, useMediaQuery } from "@mui/material";
import { ClassichomesProjects, CreateWorkshopOrder, VerifyPayment } from '../../../axios/api'
import axios from 'axios';
import Swal from "sweetalert2";

// Define custom breakpoints
const customTheme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 768,
      desktop: 1024,
    },
  },
});

const WorkshopForm = forwardRef((props, ref) => {
  const [classicCustomer, setClassicCustomer] = useState("");  // Empty string initially (no default)
  const [projects, setProjects] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    contact_number: "",
    email: "",
    preferred_workshop_date: "",
    number_of_participants: 1,
    is_classic_customer: "",
    project_id: "",
    house_number: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleCheckboxChange = (value) => {
  //   setFormData({ ...formData, is_classic_customer: value });
  // };

  const resetForm = () => {
    setFormData({
      name: "",
      contact_number: "",
      email: "",
      preferred_workshop_date: "",
      number_of_participants: 1,
      is_classic_customer: "",
      project_id: projects.length > 0 ? projects[0].id : "",
      house_number: "",
    });
    setClassicCustomer("");
  };
  //  razorpay

  const checkPaymentStatus = async (orderId) => {
    try {
      console.log(`Checking payment status for Order ID: ${orderId}`);
  
      const response = await axios.post(VerifyPayment, { order_id: orderId });
  
      if (response.data.success) {
        console.log("✅ Payment successful, showing Swal...");
        await Swal.fire({
          icon: "success",
          title: "Payment Confirmed!",
          text: "Your payment was successful. Check your email for details. Thank you!",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
  
        // ✅ Remove order ID from local storage after successful verification
        localStorage.removeItem("last_order_id");
      } else {
        console.warn("⚠️ Payment status unknown, showing Swal...");
        await Swal.fire({
          icon: "warning",
          title: "Payment Status Unknown",
          text: "We couldn't verify your payment. If you paid, please contact support.",
          confirmButtonColor: "#d33",
        });
      }
    } catch (error) {
      console.error("❌ Error verifying payment:", error);
  
      if (error.response) {
        console.error("Error response:", error.response);
  
        if (error.response.status === 429) {
          console.warn("🚨 Rate limit exceeded. Showing Swal...");
  
          // ✅ Fix: Wrap Swal in a `setTimeout` to avoid async execution conflicts
          setTimeout(() => {
            Swal.fire({
              title: "Too Many Requests",
              text: "You're making requests too quickly. Please wait a moment.",
              icon: "warning",
              timer: 3000,
              showConfirmButton: false,
            });
          }, 100); // Adding a slight delay ensures execution
  
          return; // ✅ Prevents further execution after handling 429
        }
      }
  
      setTimeout(() => {
        Swal.fire({
          icon: "error",
          title: "Payment Verification Failed",
          text: "An error occurred while verifying your payment. Please try again later.",
          confirmButtonColor: "#d33",
        });
      }, 100);
    }
  };
  



  const handleRazorpayPayment = (orderData) => {
    const expiryTime = Date.now() + 30 * 60 * 1000; // 30 minutes from now
    localStorage.setItem("last_order_id", JSON.stringify({ id: orderData.order_id, expiry: expiryTime }));

    const options = {
      key: orderData.key,
      amount: orderData.amount,
      currency: orderData.currency,
      method: {
        upi: true,
        card: true,
        netbanking: false,
        wallet: false,
        paylater: false,
        emi: false
      },
      name: "Classic Insides",
      description: "Workshop Registration",
      order_id: orderData.order_id,
      handler: async function (response) {
        try {
          Swal.fire({
            title: "Verifying Payment...",
            text: "Please wait while we confirm your payment.",
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading(); // Show loading spinner
            },
          });
          const verifyResponse = await axios.post(VerifyPayment, {
            order_id: orderData.order_id,
            payment_id: response.razorpay_payment_id,
            signature: response.razorpay_signature,
          });
          localStorage.removeItem("last_order_id");
          // ✅ Show Swal popup on success
          Swal.fire({
            icon: "success",
            title: "Registration Confirmed!",
            text: "Your payment was successful. Our team will reach out to you soon!",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          });

          resetForm();

        } catch (error) {
          console.error("Payment verification failed:", error);
          Swal.fire({
            icon: "error",
            title: "Payment Failed",
            text: "Payment verification failed. Please contact support.",
            confirmButtonColor: "#d33",
          });
        }
      },
      theme: {
        color: "#52C234",
      },
    };

    const razorpay = new window.Razorpay(options);
    razorpay.open();
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Stop form submission if validation fails
    }

    try {
      let submissionData;
      if (formData.is_classic_customer === "yes") {
        submissionData = { ...formData };
      } else {
        const { project_id, house_number, ...restData } = formData;
        submissionData = restData;
      }
      console.log(submissionData);

      const response = await axios.post(CreateWorkshopOrder, submissionData);
      console.log(response.data);

      if (response.data.order_id) {
        handleRazorpayPayment(response.data);
      }

    } catch (error) {
      console.error("Registration error:", error.response?.data || error);
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState({});
  const validateForm = () => {
    let newErrors = {};

    if (!formData.is_classic_customer) {
      newErrors.is_classic_customer = "Please select if you are a classic customer.";
    }

    if (!/^\d{10}$/.test(formData.contact_number)) {
      newErrors.contact_number = "Invalid phone number (10 digits required).";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Form is valid if there are no errors
  };

  useEffect(() => {
    // 1️⃣ Fetch projects
    axios.get(ClassichomesProjects)
      .then((response) => {
        setProjects(response.data);
        if (response.data.length > 0) {
          setFormData((prev) => ({ ...prev, project_id: response.data[0].id })); // Set first project as default
        }
      })
      .catch((error) => console.error("Error fetching data:", error));

    const storedOrder = localStorage.getItem("last_order_id");

    if (storedOrder) {
      const { id, expiry } = JSON.parse(storedOrder);

      if (Date.now() > expiry) {
        localStorage.removeItem("last_order_id"); // Remove expired order ID
      } else {
        checkPaymentStatus(id); // Only check if still valid
      }
    }

    // 3️⃣ Auto-check payment when internet reconnects
    const handleOnline = () => {
      const storedOrder = localStorage.getItem("last_order_id");

      if (storedOrder) {
        const { id, expiry } = JSON.parse(storedOrder);

        if (Date.now() <= expiry) {
          checkPaymentStatus(id);
        } else {
          localStorage.removeItem("last_order_id"); // Remove expired order ID on reconnect
        }
      }
    };

    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  // UseMediaQuery for responsive behavior
  const isMobile = useMediaQuery(customTheme.breakpoints.down("tablet")); // < 768px
  const isTablet = useMediaQuery(customTheme.breakpoints.between("tablet", "desktop")); // 768px - 1439px
  const isDesktop = useMediaQuery(customTheme.breakpoints.up("desktop")); // ≥ 1440px

  // Input field styles
  const inputStyle = {
    width: "100%",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    fontSize: isTablet || isDesktop ? "14px" : "12px",
    boxSizing: "border-box",
  };

  const labelStyle = {
    fontWeight: "500",
    marginBottom: "8px",
    fontSize: isTablet || isDesktop ? "14px" : "12px",
    display: "block",
    color: "#333",
  };

  const fieldContainerStyle = {
    marginBottom: "20px",
  };

  const getNextSaturday = () => {
    const today = new Date();
    let nextSaturday = new Date();
    nextSaturday.setDate(today.getDate() + ((6 - today.getDay() + 7) % 7 || 7)); // Find the next Saturday
    return nextSaturday.toISOString().split("T")[0]; // Format YYYY-MM-DD
  };

  const isSaturday = (dateStr) => {
    const date = new Date(dateStr);
    return date.getDay() === 6;
  };

  return (
    <ThemeProvider theme={customTheme}>
      <Box
        ref={ref}
        sx={{
          backgroundColor: "green",
          position: "relative",
          py: isMobile ? 3 : 5,
          mt: isMobile ? 12 : 18,
          px: { mobile: 2, tablet: 4, desktop: 6 },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            inset: 0,
            background:
              "linear-gradient(270deg ,rgba(0, 0, 0, 0.8) 30%, rgba(0, 0, 0, 0.6) 40%, rgba(0, 0, 0, 0.2) 80%, transparent)",
          }}
        />

        <Box
          sx={{
            position: "relative",
            maxWidth: 1200,
            mx: "auto",
            backgroundColor: "white",
            p: { mobile: 2, tablet: 3, desktop: 4 },
            borderRadius: 6,
            boxShadow: 3,
          }}
        >
          <h2
            style={{
              textAlign: "left",
              fontSize: isDesktop ? "20px" : "18px",
              marginBottom: "24px",
              color: "#52C234",
              width: isMobile ? "100%" : "45%",
            }}
          >
            Reserve Your Spot for the Classic Insides Woodworking Workshop!
          </h2>

          <p style={{ textAlign: "left", marginBottom: "24px", fontSize: isDesktop ? "14px" : "12px", }}>
            Secure Your Spot Today and Start Your Woodworking Journey!
          </p>

          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: "grid",
              gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
              gap: 2,
            }}
          >
            {/* Mobile Layout: All fields in single column */}
            {isMobile && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <div style={fieldContainerStyle}>
                  <label htmlFor="fullName" style={labelStyle}>
                    Full Name
                  </label>
                  <input id="fullName" type="text" name="name" style={inputStyle} placeholder="Enter your full name" value={formData.name} onChange={handleChange} required />
                </div>

                <div style={fieldContainerStyle}>
                  <label htmlFor="contactNumber" style={labelStyle}>
                    Contact Number
                  </label>
                  <input id="contactNumber" type="tel" name="contact_number" value={formData.contact_number} onChange={handleChange} placeholder="Enter your phone number" style={inputStyle} required />
                  {errors.contact_number && <p style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>{errors.contact_number}</p>}
                </div>

                <div style={fieldContainerStyle}>
                  <label htmlFor="emailAddress" style={labelStyle}>
                    Email Address
                  </label>
                  <input id="emailAddress" type="email" name="email" placeholder="Enter your email adress" style={inputStyle} value={formData.email} onChange={handleChange} required />
                </div>

                <div style={fieldContainerStyle}>
                  <label htmlFor="workshopDate" style={labelStyle}>
                    Preferred Workshop Date
                  </label>
                  <input
                    id="workshopDate"
                    type="date"
                    name="preferred_workshop_date"
                    placeholder="Choose date"
                    style={inputStyle}
                    value={formData.preferred_workshop_date}
                    min={getNextSaturday()}
                    onChange={(e) => {
                      const selectedDate = e.target.value;
                      if (isSaturday(selectedDate)) {
                        handleChange(e);
                        setErrors((prev) => ({ ...prev, preferred_workshop_date: "" })); // Clear error
                      } else {
                        setErrors((prev) => ({
                          ...prev,
                          preferred_workshop_date: "Please select a Saturday.",
                        }));
                      }
                    }}
                    required
                  />
                  {errors.preferred_workshop_date && (
                    <div style={{ color: "red", fontSize: "0.9em" }}>
                      {errors.preferred_workshop_date}
                    </div>
                  )}
                </div>

                <div style={fieldContainerStyle}>
                  <label htmlFor="participants" style={labelStyle}>
                    Number of Participants
                  </label>
                  <input id="participants" type="number" name="number_of_participants" placeholder="Select persons count" style={inputStyle} value={formData.number_of_participants} min="1" onChange={handleChange} required />
                </div>

                <div style={fieldContainerStyle}>
                  <label style={{ ...labelStyle, color: formData.is_classic_customer ? "#333" : "#d32f2f" }}>
                    Are you a classic customer? *
                  </label>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        id="classicCustomerYes"
                        checked={classicCustomer === "yes"}
                        onChange={() => {
                          setClassicCustomer("yes");
                          setFormData((prev) => ({ ...prev, is_classic_customer: "yes" }));
                        }}
                        sx={{
                          width: 24,
                          height: 24,
                          "& .MuiSvgIcon-root": { fontSize: 20 },
                          "&.Mui-checked": {
                            color: "white",
                            backgroundColor: "green",
                          },
                          "&.Mui-checked:hover": {
                            backgroundColor: "darkgreen",
                          },
                          borderRadius: 1,
                        }}
                      />
                      <label htmlFor="classicCustomerYes">Yes</label>
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        id="classicCustomerNo"
                        checked={classicCustomer === "no"}
                        onChange={() => {
                          setClassicCustomer("no");
                          setFormData((prev) => ({ ...prev, is_classic_customer: "no" }));
                        }}
                        sx={{
                          width: 22,
                          height: 22,
                          "& .MuiSvgIcon-root": { fontSize: 22 },
                          "&.Mui-checked": {
                            color: "white",
                            backgroundColor: "green",
                          },
                          "&.Mui-checked:hover": {
                            backgroundColor: "darkgreen",
                          },
                          borderRadius: 1,
                        }}
                      />
                      <label htmlFor="classicCustomerNo">No</label>
                    </Box>
                  </Box>
                  {errors.is_classic_customer && (
                    <p style={{ color: "#d32f2f", fontSize: "12px", marginTop: "5px" }}>
                      {errors.is_classic_customer}
                    </p>
                  )}
                </div>

                {/* Conditional fields for Mobile layout */}
                {classicCustomer === "yes" && (
                  <>
                    <div style={fieldContainerStyle}>
                      <label htmlFor="projectField" style={labelStyle}>
                        Select Project
                      </label>
                      <select
                        id="projectField"
                        name="project"
                        style={{ ...inputStyle, backgroundColor: "white" }}
                        required={classicCustomer === "yes"}
                        value={formData.project_id}
                        onChange={handleChange}
                      >
                        {projects.map((proj) => (
                          <option key={proj.id} value={proj.id}>
                            {proj.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div style={fieldContainerStyle}>
                      <label htmlFor="houseNumber" style={labelStyle}>
                        House Number
                      </label>
                      <input
                        id="houseNumber"
                        name="house_number"
                        type="text"
                        style={inputStyle}
                        value={formData.house_number}
                        onChange={handleChange}
                        required={classicCustomer === "yes"}
                      />
                    </div>
                  </>
                )}
              </Box>
            )}

            {/* Desktop/Tablet Layout: Two columns */}
            {!isMobile && (
              <>
                {/* Left Column */}
                <Box sx={{ display: "flex", flexDirection: "column", pr: { tablet: 2 } }}>
                  <div style={fieldContainerStyle}>
                    <label htmlFor="fullName" style={labelStyle}>
                      Full Name
                    </label>
                    <input id="fullName" type="text" name="name" style={inputStyle} placeholder="Enter your full name" value={formData.name} onChange={handleChange} required />
                  </div>

                  <div style={fieldContainerStyle}>
                    <label htmlFor="contactNumber" style={labelStyle}>
                      Contact Number
                    </label>
                    <input id="contactNumber" type="tel" name="contact_number" value={formData.contact_number} onChange={handleChange} placeholder="Enter your phone number" style={inputStyle} required />
                    {errors.contact_number && <p style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>{errors.contact_number}</p>}
                  </div>

                  <div style={fieldContainerStyle}>
                    <label htmlFor="emailAddress" style={labelStyle}>
                      Email Address
                    </label>
                    <input id="emailAddress" type="email" name="email" placeholder="Enter your email adress" style={inputStyle} value={formData.email} onChange={handleChange} required />
                  </div>
                </Box>

                {/* Right Column */}
                <Box sx={{ display: "flex", flexDirection: "column", pl: { tablet: 2 } }}>
                  <div style={fieldContainerStyle}>
                    <label htmlFor="workshopDate" style={labelStyle}>
                      Preferred Workshop Date
                    </label>
                    <input
                      id="workshopDate"
                      type="date"
                      name="preferred_workshop_date"
                      placeholder="Choose date"
                      style={inputStyle}
                      value={formData.preferred_workshop_date}
                      min={getNextSaturday()}
                      onChange={(e) => {
                        const selectedDate = e.target.value;
                        if (isSaturday(selectedDate)) {
                          handleChange(e);
                          setErrors((prev) => ({ ...prev, preferred_workshop_date: "" })); // Clear error
                        } else {
                          setErrors((prev) => ({
                            ...prev,
                            preferred_workshop_date: "Please select a Saturday.",
                          }));
                        }
                      }}
                      required
                    />
                    {errors.preferred_workshop_date && (
                      <div style={{ color: "red", fontSize: "0.9em" }}>
                        {errors.preferred_workshop_date}
                      </div>
                    )}
                  </div>

                  <div style={fieldContainerStyle}>
                    <label htmlFor="participants" style={labelStyle}>
                      Number of Participants
                    </label>
                    <input id="participants" type="number" name="number_of_participants" placeholder="Select persons count" style={inputStyle} value={formData.number_of_participants} min="1" onChange={handleChange} required />
                  </div>

                  <div style={fieldContainerStyle}>
                    <label style={{ ...labelStyle, color: formData.is_classic_customer ? "#333" : "#d32f2f" }}>
                      Are you a classic customer? *
                    </label>
                    <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                          id="classicCustomerYes"
                          checked={classicCustomer === "yes"}
                          onChange={() => {
                            setClassicCustomer("yes");
                            setFormData((prev) => ({ ...prev, is_classic_customer: "yes" }));
                          }}
                          sx={{
                            width: 24,
                            height: 24,
                            "& .MuiSvgIcon-root": { fontSize: 20 },
                            "&.Mui-checked": {
                              color: "white",
                              backgroundColor: "green",
                            },
                            "&.Mui-checked:hover": {
                              backgroundColor: "darkgreen",
                            },
                            borderRadius: 1,
                          }}
                        />
                        <label htmlFor="classicCustomerYes">Yes</label>
                      </Box>

                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                          id="classicCustomerNo"
                          checked={classicCustomer === "no"}
                          onChange={() => {
                            setClassicCustomer("no");
                            setFormData((prev) => ({ ...prev, is_classic_customer: "no" }));
                          }}
                          sx={{
                            width: 22,
                            height: 22,
                            "& .MuiSvgIcon-root": { fontSize: 22 },
                            "&.Mui-checked": {
                              color: "white",
                              backgroundColor: "green",
                            },
                            "&.Mui-checked:hover": {
                              backgroundColor: "darkgreen",
                            },
                            borderRadius: 1,
                          }}
                        />
                        <label htmlFor="classicCustomerNo">No</label>
                      </Box>
                    </Box>
                    {errors.is_classic_customer && (
                      <p style={{ color: "#d32f2f", fontSize: "12px", marginTop: "5px" }}>
                        {errors.is_classic_customer}
                      </p>
                    )}
                  </div>
                </Box>

                {/* Conditional Extra Fields - Only show when classicCustomer is "yes" */}
                {classicCustomer === "yes" && (
                  <Box
                    sx={{
                      gridColumn: "span 2",
                      textAlign: "left",
                      gap: 6,
                      mt: 0,
                      display: "grid",
                      gridTemplateColumns: isTablet ? "1fr" : "1fr 1fr"  // Full width for tablet, half for desktop
                    }}
                  >
                    {/* Empty Left Half - Only visible on desktop */}
                    {isDesktop && <Box />}

                    {/* Right Half with Two Fields in One Line */}
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        width: isTablet ? "100%" : "auto" // Full width for tablet
                      }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <label htmlFor="projectField" style={labelStyle}>Select Project</label>
                        <select
                          id="projectField"
                          name="project"
                          style={{ ...inputStyle, backgroundColor: "white" }}
                          required={classicCustomer === "yes"}
                          value={formData.project_id}
                          onChange={handleChange}
                        >
                          {projects.map((proj) => (
                            <option key={proj.id} value={proj.id}>
                              {proj.name}
                            </option>
                          ))}
                        </select>
                      </Box>

                      <Box sx={{ width: "50%" }}>
                        <label htmlFor="houseNumber" style={labelStyle}>House Number</label>
                        <input
                          id="houseNumber"
                          name="house_number"
                          type="text"
                          style={inputStyle}
                          value={formData.house_number}
                          onChange={handleChange}
                          required={classicCustomer === "yes"}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
              </>
            )}

            {/* Submit Button - Works for both layouts */}
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column-reverse" : "row",
                justifyContent: isMobile ? "center" : "space-between",
                alignItems: isMobile ? "stretch" : "center",
                width: "100%",
                gridColumn: "span 2",
                mt: 2,
                gap: isMobile ? 2 : 0
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: "text.secondary",
                  maxWidth: isMobile ? "100%" : "400px",
                  textAlign: "left",
                  mb: isMobile ? 2 : 0
                }}
              >
                We respect your privacy and will never share your information.
              </Typography>

              <Button
                variant="contained"
                type="submit"
                sx={{
                  bgcolor: "green",
                  "&:hover": { bgcolor: "darkgreen" },
                  padding: "12px 32px",
                  fontSize: isDesktop ? "15px" : "14px",
                  width: isMobile ? "100%" : "auto"
                }}
              >
                Submit Registration
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
});

export default WorkshopForm;