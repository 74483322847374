import React, { useContext } from 'react';
import { styled } from 'styled-components';

import closeIcon from '../../assets/images/icons/close-icon.svg';
import { HeaderContext } from '../../context/StoreContext';

import { useNavigate } from 'react-router-dom';

function DropdownMenu() {
    const { state, dispatch } = useContext(HeaderContext);
    const navigate = useNavigate();

    const handleMenuToggle = () => {
        if (!state.active) {
            document.querySelector("html").classList.add("model-enabled");
            document.querySelector("body").classList.add("model-enabled");
            document.querySelector("#root").classList.add("model-enabled");
        } else {
            document.querySelector("html").classList.remove("model-enabled");
            document.querySelector("body").classList.remove("model-enabled");
            document.querySelector("#root").classList.remove("model-enabled");
        }
        dispatch({
            type: 'CLOSE_MENU'
        });
    };

    const handleClick = (path) => {
        navigate(path);
        handleMenuToggle();
    };

    return (
        <Section>
            <Container>
                <CloseButton>
                    <Img onClick={handleMenuToggle}>
                        <img src={closeIcon} alt="Close" />
                    </Img>
                </CloseButton>
                <MenuList>
                    <MenuItem onClick={() => handleClick('/')}> <p>Home</p> </MenuItem>
                    <MenuItem onClick={() => handleClick('/about')}> <p>About</p> </MenuItem>
                    <MenuItem onClick={() => handleClick('/factory-info')}> <p>Factory</p> </MenuItem>
                    <MenuItem onClick={() => handleClick('/projects')}> <p>Projects</p> </MenuItem>
                    <MenuItem onClick={() => handleClick('/services')}> <p>Services</p> </MenuItem>
                    <MenuItem onClick={() => handleClick('/contact')}> <p>Contact</p> </MenuItem>
                    <MenuItem onClick={() => handleClick('/blogs')}> <p>Blogs</p> </MenuItem>
                    <MenuItem onClick={() => handleClick('/workshop')}> <p>Workshop</p> </MenuItem>
                </MenuList>
                <TalkButton href='tel:+919072341177'> Talk with an Expert </TalkButton>
            </Container>
        </Section>
    );
}

export default DropdownMenu;

const Section = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: end;
    top: 80px;
    z-index: 2000;
    cursor: pointer;

    @media (min-width: 2000px) { top: 130px; }
    @media (max-width: 1999px) { top: 100px; }
    @media (max-width: 1750px) { top: 75px; }
    @media (max-width: 800px) { top: 65px; }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: var(--secondary-cl);
    width: calc(201px + 10%);
    opacity: 80%;
    padding: 20px;
    box-sizing: border-box;
    user-select: none;

    max-height: 85vh; /* Prevent it from growing too much */
    overflow-y: auto; /* Allow scrolling when needed */

    @media (max-width: 1024px) { max-height: 90vh; }
    @media (max-width: 580px) { max-height: 95vh; }
`;

const CloseButton = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    height: 70px;
    width: 80%;
    padding: 10px;
    margin: 0 auto;
`;

const MenuList = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto; /* Make sure it scrolls */
`;

const MenuItem = styled.div`
    display: flex;
    color: #fff;
    font-size: 14px;
    font-family: 'raleway_regular';
    border: none;
    border-top: 2px solid #fff;
    margin: 15px 0px;
    height: 60px;
    padding: 3px 2px;
    width: 65%;
    margin: 0px auto;
    transition: all 0.5s ease;

    &:hover {
        border-top: 2px solid orange;
    }
    &:hover p {
        animation: slow-move 1.3s normal;
        text-align: end;
        width: 100%;

        @keyframes slow-move {
            0% { width: 0%; }
            100% { width: 100%; }
        }
    }
    p {
        margin: 0;
        text-align: end;
        transition: width 1s ease;
    }
`;

const Img = styled.div`
    display: block;
    width: 25px;
    background-color: var(--secondary-cl);
    opacity: 70%;

    img {
        width: 100%;
        display: block;
    }
`;

const TalkButton = styled.a`
    padding: 10px 20px;
    border: none;
    background-color: var(--primary-cl);
    font-family: 'raleway_light';
    color: #fff;
    display: flex;
    margin: 15px auto;
    font-size: 14px;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    width: 65%;

    @media (max-width: 580px) {
        width: 80%;
        padding: 12px 12px;
    }
`;
