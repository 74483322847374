import { Paper, Box, Button, Typography, ThemeProvider, createTheme, useMediaQuery } from "@mui/material";
import React from "react";
import bg from "../../../assets/images/workshop/detail_cover.png";
import img from "../../../assets/images/workshop/img.jpg";
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

const WorkshopDetails = ({scrollToForm}) => {
  // Define custom breakpoints inside the component
  const customTheme = createTheme({
    breakpoints: {
      values: {
        mobile: 0,
        tablet: 768,
        desktop: 1024,
      },
    },
  });

  // UseMediaQuery for responsiveness
  const isMobile = useMediaQuery(customTheme.breakpoints.down("tablet")); // < 768px
  const isTablet = useMediaQuery(customTheme.breakpoints.between("tablet", "desktop")); // 768px - 1439px
  const isLargeScreen = useMediaQuery(customTheme.breakpoints.up("desktop")); // >= 1440px

  return (
    <ThemeProvider theme={customTheme}>
      <Paper
        elevation={0}
        sx={{
          position: "relative",
          width: "100%",
          height: isMobile ? "705px" : isTablet ? "654px" : "700px",
          overflow: "hidden",
          margin: 0,
          padding: 0,
          borderRadius: 0,
        }}
      >
        {/* Background Image Overlay */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${bg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            opacity: 0.3,
            px:2,
            zIndex: 0,
          }}
        />

        {/* Content Layout */}
        <Box
          sx={{
            position: "relative",
            width: "100%",
            minHeight: "500px",
            display: "flex",
            flexDirection: isMobile ? "column-reverse" : "row",
            alignItems: "stretch",
            justifyContent: "center",
            padding: isMobile ? "20px" : "40px",
            gap: isMobile ? "20px" : "50px",
            zIndex: 1,
            boxSizing: "border-box",
            marginTop: isMobile ? 6 : 10,
          }}
        >
          {/* Left Section - Workshop Details */}
          <Box
            sx={{
              width: isMobile ? "90%" : isTablet ? "50%" : "30%",
              textAlign: "left",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              borderTop: "4px solid #1976d2",
              bgcolor: "white",
              borderRadius: 2,
              padding: isMobile ? 2 : 4,
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography variant="h4" sx={{ fontSize: "20px", fontWeight: 700 }}>
              Workshop Details
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                mt: 0.5,
                fontWeight: 500,
                color: "text.secondary",
              }}
            >
              Woodworking
            </Typography>

            <Box sx={{ mt: isMobile ? 4 : isTablet ? 1 : 2, display: "flex", alignItems: "baseline" }}>
              <Typography sx={{ fontSize: "32px", fontWeight: 600, color: "text.primary" }}>
                ₹1200
              </Typography>
              <Typography sx={{ fontSize: "12px", fontWeight: 400, color: "text.secondary", ml: 0.5 }}>
                /class
              </Typography>
            </Box>

            <Box sx={{ mt: 3 }}>
              {/* Time with Clock Icon */}
              <Typography sx={{ fontSize: "14px", mb: 1.5, display: "flex", alignItems: "center",fontFamily: "'Urbanist', sans-serif", }}>
                <AccessTimeOutlinedIcon sx={{ fontSize: "18px", mr: 2, color: "blue" }} />
                Every Saturday,{" "} <Typography component="span" sx={{ fontWeight: "bold", ml: 0.5 }}>3:30 PM - 6:30 PM</Typography>
              </Typography>

              <Typography
                sx={{
                  fontSize: "14px",
                  mb: 1.5,
                  display: "flex",
                  alignItems: "center",
                  width: "80%",
                  fontFamily: "'Urbanist', sans-serif",
                }}
              >
                <LocationOnOutlinedIcon sx={{ fontSize: "18px", mr: 2, color: "blue", flexShrink: 0 }} />
                <Box component="span">
                  Classic{" "}
                  <Typography component="span" sx={{ fontFamily: "'Urbanist', sans-serif",fontWeight: "bold", display: "inline" }}>
                    Insides Factory,
                  </Typography>{" "}
                  KINFRA, Info Park, Kakkanad
                </Box>
              </Typography>



              {/* Pricing with Tick Icon */}
              <Typography sx={{ fontSize: "14px", mb: 1.5, display: "flex", alignItems: "center" ,fontFamily: "'Urbanist', sans-serif",}}>
                <CheckOutlinedIcon sx={{ fontSize: "18px", mr: 2, color: "blue" }} />
                2 or More People:{" "} <Typography component="span" sx={{ fontWeight: "bold", ml: 0.5 }}>₹1000 per class</Typography>
              </Typography>

              <Typography sx={{ fontSize: "14px", mb: 1.5, display: "flex", alignItems: "center" ,fontFamily: "'Urbanist', sans-serif",}}>
                <CheckOutlinedIcon sx={{ fontSize: "18px", mr: 2, color: "blue" }} />
                Classic Customers:  <Typography component="span" sx={{ fontWeight: "bold", ml: 0.5 }}>₹850 per class</Typography>
              </Typography>
            </Box>

            <Button
            onClick={scrollToForm}
              variant="contained"
              sx={{
                mt: 4,
                backgroundColor: "#1976d2",
                color: "white",
                width: isMobile ? "100%" : "auto",
                alignSelf: "flex-start",
                px: 4,
                py: 1,
              }}
            >
              Register Now
            </Button>
          </Box>

          {/* Right Section - Workshop Image */}
          <Box
            sx={{
              width: isMobile ? "100%" : "50%",
              backgroundImage: `url(${img})`,
              backgroundSize: "cover",
              backgroundPosition: isTablet
              ? "39% 85%" // Moves the image slightly up (15%) and left (45%)
              : "top center",
              borderRadius: 4,
            }}
          />
        </Box>
      </Paper>
    </ThemeProvider>
  );
};

export default WorkshopDetails;
