import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

// Import your images
import first from '../../../src/assets/images/projectPage/1.jpg';
import second from '../../../src/assets/images/projectPage/2.jpg';
import third from '../../../src/assets/images/projectPage/3.jpg';
import fourth from '../../../src/assets/images/projectPage/4.jpg';
import fifth from '../../../src/assets/images/projectPage/5.jpg';

function NewArrivalSlider() {
  return (
    <Section>
      <h1>New  <br/> Arrivals</h1>
      <SwiperWrapper>
        <Swiper
          spaceBetween={16}
          slidesPerView={4.2} 
          pagination={{ clickable: true, el: '.custom-pagination' }} 
          modules={[Pagination]}
          breakpoints={{
            320: {
              slidesPerView: 2.2, 
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 3.2, 
              spaceBetween: 16,
            },
            1024: {
              slidesPerView: 4.2, 
              spaceBetween: 16,
            },
          }}
        >
          <SwiperSlide>
            <SlideContent>
              <ImageWrapper>
                <img src={first} alt="Loveseat Sofa" />
                <Button>Enquire Now</Button>
              </ImageWrapper>
              <TextWrapper>
                <h3>Loveseat Sofa</h3>
                <p>
                  Rs14999.00 <span>Rs20000.00</span>
                </p>
              </TextWrapper>
            </SlideContent>
          </SwiperSlide>
          <SwiperSlide>
            <SlideContent>
              <ImageWrapper>
                <img src={second} alt="Table Lamp" />
                <Button>Enquire Now</Button>
              </ImageWrapper>
              <TextWrapper>
                <h3>Table Lamp</h3>
                <p>Rs14999.00</p>
              </TextWrapper>
            </SlideContent>
          </SwiperSlide>
          <SwiperSlide>
            <SlideContent>
              <ImageWrapper>
                <img src={third} alt="Beige Table Lamp" />
                <Button>Enquire Now</Button>
              </ImageWrapper>
              <TextWrapper>
                <h3>Beige Table Lamp</h3>
                <p>Rs14999.00</p>
              </TextWrapper>
            </SlideContent>
          </SwiperSlide>
          <SwiperSlide>
            <SlideContent>
              <ImageWrapper>
                <img src={fourth} alt="Bamboo Basket" />
                <Button>Enquire Now</Button>
              </ImageWrapper>
              <TextWrapper>
                <h3>Bamboo Basket</h3>
                <p>Rs14999.00</p>
              </TextWrapper>
            </SlideContent>
          </SwiperSlide>
          <SwiperSlide>
            <SlideContent>
              <ImageWrapper>
                <img src={fifth} alt="Toasted" />
                <Button>Enquire Now</Button>
              </ImageWrapper>
              <TextWrapper>
                <h3>Toasted</h3>
                <p>Rs14999.00</p>
              </TextWrapper>
            </SlideContent>
          </SwiperSlide>
        </Swiper>
        <PaginationWrapper className="custom-pagination" />
      </SwiperWrapper>
    </Section>
  );
}

export default NewArrivalSlider;

// Styled Components
const Section = styled.div`
  width: 80%;
  margin: 30px auto;
  position: relative;
  overflow: hidden;

  h1 {
    text-align: left;
    font-size: 2em;
    margin-bottom: 20px;
  }
`;

const SwiperWrapper = styled.div`
  overflow: visible; // Ensure the images are partially visible
`;

const SlideContent = styled.div`
  position: relative;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  img {
    width: 100%; // Image takes full width of the wrapper
    height: 400px; // Adjust the height as needed
    object-fit: cover;
    border-radius: 8px;
  }
`;

const Button = styled.button`
  width: 90%;
  position: absolute;
  bottom: 20px; // Positioned at the bottom inside the image
  left: 50%;
  transform: translateX(-50%);
  padding: 20px 40px;
  background-color: #ff6600;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;

  ${ImageWrapper}:hover & {
    opacity: 1;
  }

  &:hover {
    background-color: #e65c00; // Change the button color on hover
  }
`;

const TextWrapper = styled.div`
  text-align: center;
  margin-top: 10px;
  h3 {
    font-size: 1.2em;
    margin-bottom: 5px;
  }

  p {
    font-size: 1em;
    color: #000;
    margin: 0;

    span {
      text-decoration: line-through;
      color: #888;
      margin-left: 10px;
    }
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background-color: #ccc; // Default dot color
    border-radius: 50%;
    margin: 0 5px; // Gap between dots
    opacity: 0.7;
    transition: background-color 0.3s ease;
  }

  .swiper-pagination-bullet-active {
    background-color: #000; // Active dot color
    opacity: 1;
  }
`;
