import React from "react";
import { Paper, Box, Typography, Container, Grid, ThemeProvider, createTheme, useMediaQuery } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const WorkshopAwaits = () => {
  // Define custom breakpoints
  const customTheme = createTheme({
    breakpoints: {
      values: {
        mobile: 0,
        tablet: 768,
        desktop: 1024,
      },
    },
  });

  // UseMediaQuery for responsive layout
  const isMobile = useMediaQuery(customTheme.breakpoints.down("tablet")); // < 768px
  const isTablet = useMediaQuery(customTheme.breakpoints.between("tablet", "desktop")); // 768px - 1439px
  const isDesktop = useMediaQuery(customTheme.breakpoints.up("desktop")); // ≥ 1440px

  return (
    <ThemeProvider theme={customTheme}>
      <Paper
        elevation={0}
        sx={{
          width: "100%",
          backgroundColor: "white",
          py: isMobile ? 6 : 8,
          mt: { xs: 8, md: 8 },
          mb: -8,
          borderRadius: 0,
        }}
      >
        <Container maxWidth="lg">
          <Box sx={{ textAlign: isMobile ? "left":"center", mb: isMobile ? 4 : 6 ,ml:isMobile?2:0}}>
            <Typography
              variant="h4"
              component="h2"
              sx={{
                width: isMobile? "60%":"100%",  
                fontFamily: "'Urbanist', sans-serif",
                fontWeight: 700,
                fontSize: isMobile ? "20px" :isMobile ? "32px":"40px",
                mb: 2,
              }}
            >
              What Awaits You at Our Workshop
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: isMobile ? "12px" : "14px",
                color: "text.secondary",
                maxWidth: "700px",
                mx: "auto",
              }}
            >
              Progressive learning tailored for everyone—whether you're a beginner or a DIY pro.
            </Typography>
          </Box>

          <Grid container spacing={isMobile ? 2 : 3} justifyContent="center">
            {[
              { title: "Basic", color: "#FEFEA7", iconColor: "#FFD900", text: "Basic tools and simple models" },
              {
                title: "Advanced",
                color: "#FFCD95",
                iconColor: "#FF4C0B",
                text: "Advanced techniques like Tenon Mortise, Dovetail, and Groove joints.",
              },
              { title: "Pro", color: "#3CCE46", iconColor: "#FFFFFF", text: "Build your own custom model" },
            ].map((item, index) => (
              <Grid
                key={index}
                item
                sx={{
                  width: isMobile ? "100%" : "32%", // Full width for mobile, 3 boxes per row for tablet+
                  display: "flex",
                  justifyContent: "center",
                  mb: isMobile ? 2 : 0, 
                }}
              >
                <Box
                  sx={{
                    width: isMobile ? "100%" : index === 1 ? "355px" : "267px", // Wider middle box only on tablets+
                    backgroundColor: item.color,
                    borderRadius: 8,
                    p: isMobile ? 1 : isTablet?2 : 3,
                    m:isMobile ? 2 :0,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                    "&:hover": {
                      transform: "translateY(-5px)",
                      boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.1)",
                    },
                  }}
                >
                  <CheckCircleIcon sx={{ color: item.iconColor, fontSize: 40, m: 2 }} />
                  <Typography variant="h6" sx={{ fontWeight: 600, fontSize: "18px", mb: 1 }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "text.secondary", fontSize: "14px" }}>
                    {item.text}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>

        </Container>
      </Paper>
    </ThemeProvider>
  );
};

export default WorkshopAwaits;
