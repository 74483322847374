// export const BaseUrl="https://classicinsides-admin.accoladesweb.com/api/";
export const BaseUrl="https://backend.classicinsides.com/api/";
export const LocalUrl="http://127.0.0.1:8000/api/";

export const PrimaryEnquiry=BaseUrl + 'project/add-enquiry/';
export const QuickEnquiry=BaseUrl + 'project/quick-enquiry/';
export const BookFactoryVisitApi=BaseUrl + 'factory/add-book-factory/';

//api endpoints

export const BannerListApi=BaseUrl + 'web/list-banner/';

export const FactoryDetailsApi=BaseUrl + 'factory/list-factory-banner/';

export const FactoryMachineListApi=BaseUrl + 'factory/list-factory-assets';

export const ListProjectImagesApi = BaseUrl + 'project/list-project-image/';

export const ListProjectsApi = BaseUrl + 'project/list-project/';

export const ListSingleProjectApi = BaseUrl + 'project/list-single-project/';

export const ListTestimonialApi = BaseUrl + 'web/list-testimonials/';

export const ListBlogsApi = BaseUrl + 'web/list-blog/';

export const ListSingleBlogsApi = BaseUrl + 'web/list-single-blog/';

export const ListServices = BaseUrl + 'project/list-service/';

export const ListVisibleServices = BaseUrl + 'project/list-service-visibility/';

export const ListSingleService = BaseUrl + 'project/list-single-service/';

export const GetSingleContent = BaseUrl + 'web/list-page-googlecontent/';

export const ListSingleSeoData = BaseUrl + 'web/list-link-seo/';


//workshop page endpoints

export const ClassichomesProjects = BaseUrl + 'workshop/classic-home-projects/';
export const WorkshopTestimonials = BaseUrl + 'workshop/testimonials/';
export const WorkshopFaqs = BaseUrl + 'workshop/faqs/';
export const WorkshopOne = BaseUrl + 'workshop/workshops/';

// workshop order creation and payment verification for razorpay
export const CreateWorkshopOrder = BaseUrl + 'workshop/create-order/';
export const VerifyPayment = BaseUrl + 'workshop/verify-payment/';

